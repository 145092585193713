import axios from "axios";
import { jwtDecode } from "jwt-decode";
import io from "socket.io-client";

const abortControllers = {};
let currentSocket = null;
let currentRoom = {};

const cache = {};
const CACHE_DURATION = 5 * 60 * 1000;
const cacheableEndpoints = [
    "admin/searchMission",
    "admin/getTempWorkerPostulateMission",
    "admin/getMissionDetailsById",
    "admin/getTempWorkerInviteMission",
];

const generateCacheKey = (url, data) => {
    const dataString = JSON.stringify(data || {});
    return `${url}|${dataString}`;
}

const updateCacheEntry = (url, data, newData) => {
    const cacheKey = generateCacheKey(url, data);
    if (cache[cacheKey]) {
        cache[cacheKey] = {
            data: newData,
            timestamp: Date.now(),
        };
    }
}

const isCacheValid = (cacheEntry) => {
    if (!cacheEntry) return false;
    return (Date.now() - cacheEntry.timestamp) < CACHE_DURATION;
}

const connectSocketWithToken = async () => {
    try {
        const token = localStorage.getItem("xsrfToken");
        if (token) {
            const decodedToken = jwtDecode(token);

            const newRoom = {
                userId: decodedToken.id || "",
            };

            // Check if current socket is connected to the correct room
            if (
              currentSocket &&
              currentRoom.userId === newRoom.userId
            ) {
                console.log("Socket already connected to the correct room");
                return currentSocket;
            }

            // If socket exists but not connected to the correct room, disconnect it
            if (currentSocket) {
                console.log("Disconnecting current socket");
                currentSocket.disconnect();
            }

            const socket = io(process.env.REACT_APP_API_URL_SOCKET, {
                transportOptions: {
                    polling: {
                        extraHeaders: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                },
            });

            socket.on("connect", () => {
                socket.emit("joinRoom", newRoom);
            });

            currentSocket = socket;
            currentRoom = newRoom;
            return socket;
        }
        return null;
    } catch (error) {
        console.error("Error connecting Socket.IO:", error);
    }
};

const disconnectSocket = () => {
    if (currentSocket) {
        currentSocket.disconnect();
        currentSocket = null;
        currentRoom = {};
        console.log("Socket disconnected");
    }
};

const renewTokenIfNeededAndConnectSocket = async () => {
    try {
        return await connectSocketWithToken();
    } catch (error) {
        console.error("Error renewing token and connecting Socket.IO:", error);
    }
};

const getAbortController = (key) => {
    if (abortControllers[key]) {
        abortControllers[key].abort();
    }
    abortControllers[key] = new AbortController();
    return abortControllers[key];
};

const makeRequest = async (url, method, data, resolve, reject) => {
    try {
        const controller = getAbortController(url + JSON.stringify(data));
        const isCacheable = cacheableEndpoints.some(endpoint => url.startsWith(endpoint));
        const cacheKey = isCacheable ? generateCacheKey(url, data) : null;

        if (isCacheable && cacheKey && cache[cacheKey] && isCacheValid(cache[cacheKey])) {
            resolve(cache[cacheKey].data);
            return;
        }

        const response = await axios({
            method,
            url: `${process.env.REACT_APP_API_URL}${url}`,
            withCredentials: true,
            data,
            signal: controller.signal,
        });

        if (response.data.errors) {
            reject(response.data.errors);
        } else {
            if (isCacheable && cacheKey) {
                cache[cacheKey] = {
                    data: response.data,
                    timestamp: Date.now(),
                };
            }
            resolve(response.data);
        }
    } catch (error) {
        reject(error);
    }
}

const cleanUpCache = () => {
    const now = Date.now();
    Object.keys(cache).forEach(key => {
        if ((now - cache[key].timestamp) > CACHE_DURATION) {
            delete cache[key];
        }
    });
}

setInterval(cleanUpCache, 60 * 1000);

connectSocketWithToken();

const ApiDatabase = {
    getUserProfile: (payload, resolve, reject) => makeRequest("user/info", "post", payload, resolve, reject),
    getTempWorker: (payload, resolve, reject) => makeRequest("tempWorker/info", "post", payload, resolve, reject),
    postRoleUser: (payload, resolve, reject) => makeRequest("user/role", "post", payload, resolve, reject),
    postPasswordRefresh: (payload, resolve, reject) => makeRequest("user/update/password", "put", payload, resolve, reject),
    postEmailRefresh: (payload, resolve, reject) => makeRequest("user/update/email", "put", payload, resolve, reject),
    postResendEmailToken: (payload, resolve, reject) => makeRequest("resend/tokenMail", "post", payload, resolve, reject),
    postCreateTokenMail: (payload, resolve, reject) => makeRequest("resend/createToken", "post", payload, resolve, reject),
    getListLanguages: (resolve, reject) => makeRequest("language", "get", {}, resolve, reject),
    postUserLanguage: (payload, resolve, reject) => makeRequest("tempWorker/create/language", "post", payload, resolve, reject),
    deleteUserLanguage: (payload, resolve, reject) => makeRequest("tempWorker/delete/languages", "delete", payload, resolve, reject),
    getListTools: (resolve, reject) => makeRequest("tools", "get", {}, resolve, reject),
    postUserTool: (payload, resolve, reject) => makeRequest("tempWorker/create/tools", "post", payload, resolve, reject),
    postUserToolDelete: (payload, resolve, reject) => makeRequest("tempWorker/delete/tools", "delete", payload, resolve, reject),
    deleteUserCV: (payload, resolve, reject) => makeRequest("tempWorker/delete/cv", "delete", payload, resolve, reject),
    getInfoMission: (payload, resolve, reject) => makeRequest(`mission/${payload.id}`, "get", payload, resolve, reject),
    postCandidate: (payload, resolve, reject) => makeRequest("mission/addUser", "post", payload, resolve, reject),
    postVerifyUser: (payload, resolve, reject) => makeRequest("mission/verify", "post", payload, resolve, reject),
    deleteUserMission: (payload, resolve, reject) => makeRequest("mission/deleteUser", "delete", payload, resolve, reject),
    getPathFile: (payload, resolve, reject) => makeRequest(`upload/getSingleFile/${payload.id}`, "get", payload, resolve, reject),
    postCreateExp: (payload, resolve, reject) => makeRequest("tempWorker/create/experience", "post", payload, resolve, reject),
    putUpdateExp: (payload, resolve, reject) => makeRequest("tempWorker/update/experience", "put", payload, resolve, reject),
    postDeleteExp: (payload, resolve, reject) => makeRequest("tempWorker/delete/experiences", "delete", payload, resolve, reject),
    getAllLicence: (resolve, reject) => makeRequest("licence", "get", {}, resolve, reject),
    postDeleteLicence: (payload, resolve, reject) => makeRequest("tempWorker/delete/licence", "delete", payload, resolve, reject),
    postDeleteFormation: (payload, resolve, reject) => makeRequest("tempWorker/delete/training", "delete", payload, resolve, reject),
    postUpdateIban: (payload, resolve, reject) => makeRequest("tempWorker/update/bank", "put", payload, resolve, reject),
    postUpdatePhone: (payload, resolve, reject) => makeRequest("user/update/phone", "put", payload, resolve, reject),
    postUpdateAddress: (payload, resolve, reject) => makeRequest("address/add", "post", payload, resolve, reject),
    postUpdateAddressTempWorker: (payload, resolve, reject) => makeRequest("admin/address/add", "post", payload, resolve, reject),
    getAddress: (payload, resolve, reject) => makeRequest(`address/${payload.id}`, "get", payload, resolve, reject),
    getTimeSheets: (payload, resolve, reject) => makeRequest("admin/timeSheets/allInfo", "post", payload, resolve, reject),
    getTimeSheetInfo: (payload, resolve, reject) => makeRequest(`timeSheets/${payload.id}`, "get", payload, resolve, reject),
    getTimeSheetDay: (payload, resolve, reject) => makeRequest(`timeSheets/getTimeSheetDay/${payload.id}`, "get", payload, resolve, reject),
    postUpdateDay: (payload, resolve, reject) => makeRequest("timeSheets/updateDayAdmin", "put", payload, resolve, reject),
    getAllUsersWithIdentityFilesToComplete: (payload, resolve, reject) => makeRequest("admin/tempWorkersWithIdentityFilesToComplete", "post", payload, resolve, reject),
    getUserWithIdentityFilesToComplete: (payload, resolve, reject) => makeRequest("admin/tempWorkerWithIdentityFilesToComplete", "post", payload, resolve, reject),
    postRefuseTempWorkerFileAdmin: (payload, resolve, reject) => makeRequest("admin/refuseTempWorkerFileAdmin", "post", payload, resolve, reject),
    postAcceptTempWorkerFileAdmin: (payload, resolve, reject) => makeRequest("admin/acceptTempWorkerFileAdmin", "post", payload, resolve, reject),
    getTempWorkerById: (payload, resolve, reject) => makeRequest(`tempWorker/${payload.adminTempWorkerId}`, "get", {}, resolve, reject),
    getTempWorkerByIdUser: (payload, resolve, reject) => makeRequest(`tempWorker/tempWorkerByIdUser/${payload.idUser}`, "get", {}, resolve, reject),
    getUserById: (payload, resolve, reject) => makeRequest(`user/${payload.id_user}`, "get", {}, resolve, reject),
    postAcceptTempWorkerFileIdentityAdmin: (payload, resolve, reject) => makeRequest("admin/acceptTempWorkerFileIdentityAdmin", "post", payload, resolve, reject),
    postAcceptTempWorkerFileVisaAdmin: (payload, resolve, reject) => makeRequest("admin/acceptTempWorkerFileVisaAdmin", "post", payload, resolve, reject),
    postAcceptTempWorkerFileVitalCardAdmin: (payload, resolve, reject) => makeRequest("admin/acceptTempWorkerFileVitalCardAdmin", "post", payload, resolve, reject),
    getTimeSheetsBeSTT: (payload, resolve, reject) => makeRequest("admin/getTimeSheetsBeSTT", "post", payload, resolve, reject),
    postUpdateTimeSheet: (payload, resolve, reject) => makeRequest("timeSheets/updateCompany", "put", payload, resolve, reject),
    getCompanies: (payload, resolve, reject) => makeRequest(`admin/getCompaniesList?token=${payload.token}&search=${payload.search}&page=${payload.page}&limit=${payload.limit}&optionSearch=${payload.optionSearch}`, "get", payload, resolve, reject),
    getCompanyInfo: (payload, resolve, reject) => makeRequest(`admin/getCompanyInfo?token=${payload.token}&idCompany=${payload.id}`, "get", {}, resolve, reject),
    getMinimumWage: (resolve, reject) => makeRequest("admin/getMinimumWage", "get", {}, resolve, reject),
    postCoefficient: (payload, resolve, reject) => makeRequest("admin/postCoefficient", "post", payload, resolve, reject),
    searchMission: (payload, resolve, reject) => makeRequest("admin/searchMission", "post", payload, resolve, reject),
    getMissionDetailsById: (payload, resolve, reject) => makeRequest(`admin/getMissionDetailsById?token=${payload.token}&idMission=${payload.idMission}`, "get", {}, resolve, reject),
    searchTempWorker: (payload, resolve, reject) => makeRequest("admin/searchTempWorker", "post", payload, resolve, reject),
    modifyMissionPeriod: (payload, resolve, reject) => makeRequest("mission/modifyMissionPeriod", "post", payload, resolve, reject),
    assignTempWorkerToMission: (payload, resolve, reject) => makeRequest("mission/assign", "post", payload, resolve, reject),
    requestSignContract: (payload, resolve, reject) => makeRequest("admin/requestSignContract", "post", payload, resolve, reject),
    getJobs: (payload, resolve, reject) => makeRequest(`hrFlowJobs/getJobs?token=${payload.token}`, "get", {}, resolve, reject),
    postJob: (payload, resolve, reject) => makeRequest("hrFlowJobs/addJob", "post", payload, resolve, reject),
    putJob: (payload, resolve, reject) => makeRequest("hrFlowJobs/updateJob", "put", payload, resolve, reject),
    patchJob: (payload, resolve, reject) => makeRequest("hrFlowJobs/deleteJob", "patch", payload, resolve, reject),
    getTempWorkerPostulateMission: (payload, resolve, reject) => makeRequest("admin/getTempWorkerPostulateMission", "post", payload, resolve, reject),
    unassignMission: (payload, resolve, reject) => makeRequest("admin/unassignMission", "post", payload, resolve, reject),
    putDisplayCostEstimate: (payload, resolve, reject) => makeRequest("admin/updateDisplayCostEstimate", "put", payload, resolve, reject),
    getDisplayCostEstimate: (payload, resolve, reject) => makeRequest(`admin/getDisplayCostEstimate?token=${payload.token}&idCompany=${payload.idCompany}`, "get", payload, resolve, reject),
    getScheduleByIdMission: (payload, resolve, reject) => makeRequest(`admin/getScheduleByIdMission?token=${payload.token}&idMission=${payload.idMission}`, "get", payload, resolve, reject),
    linkCompanyToBigAccount: (payload, resolve, reject) => makeRequest("admin/linkCompanyToBigAccount", "post", payload, resolve, reject),
    getBigAccounts: (payload, resolve, reject) => makeRequest("admin/getBigAccounts", "post", payload, resolve, reject),
    addBigAccount: (payload, resolve, reject) => makeRequest("admin/addBigAccount", "post", payload, resolve, reject),
    getCompanyBigAccount: (payload, resolve, reject) => makeRequest(`admin/getCompanyBigAccount?token=${payload.token}&idCompany=${payload.idCompany}`, "get", payload, resolve, reject),
    updScheduleByIdMission: (payload, resolve, reject) => makeRequest("mission/updScheduleByIdMission", "put", payload, resolve, reject),
    deleteCompanyBigAccount: (payload, resolve, reject) => makeRequest("admin/deleteCompanyBigAccount", "delete", payload, resolve, reject),
    deleteBigAccount: (payload, resolve, reject) => makeRequest("admin/deleteBigAccount", "delete", payload, resolve, reject),
    requestGenerationContract: (payload, resolve, reject) => makeRequest("admin/requestGenerationContract", "post", payload, resolve, reject),
    postAddCompany: (payload, resolve, reject) => makeRequest("company/add", "post", payload, resolve, reject),
    getReasonRefusesDocuments: (payload, resolve, reject) => makeRequest(`admin/reasonRefusesDocuments?token=${payload.token}`, "get", payload, resolve, reject),
    getRatingTreatedTempWorkers: (payload, resolve, reject) => makeRequest(`admin/ratingTreatedTempWorkers?token=${payload.token}&search=${payload.search}&limit=${payload.limit}&page=${payload.page}`, "get", payload, resolve, reject),
    getRatingUntreatedTempWorkers: (payload, resolve, reject) => makeRequest(`admin/ratingUntreatedTempWorkers?token=${payload.token}&search=${payload.search}&limit=${payload.limit}&page=${payload.page}`, "get", payload, resolve, reject),
    getRatingTempWorker: (payload, resolve, reject) => makeRequest(`admin/ratingTempWorker?token=${payload.token}&idUser=${payload.idUser}&idMission=${payload.idMission}`, "get", payload, resolve, reject),
    getRatingTempWorkerByMission: (payload, resolve, reject) => makeRequest(`admin/ratingTempWorkerByMission?token=${payload.token}&idUser=${payload.idUser}&idMission=${payload.idMission}`, "get", payload, resolve, reject),
    postTreatmentRatingTempWorker: (payload, resolve, reject) => makeRequest("admin/treatmentRatingTempWorker", "post", payload, resolve, reject),
    getNbRatingUntreatedTempWorkers: (payload, resolve, reject) => makeRequest(`admin/nbRatingUntreatedTempWorkers?token=${payload.token}`, "get", payload, resolve, reject),
    getReviewsByTempWorkerGroupByMissions: (payload, resolve, reject) => makeRequest(`admin/reviewsByTempWorkerGroupByMissions?token=${payload.token}&idUser=${payload.idUser}`, "get", payload, resolve, reject),
    getReviewsByTempworkerByMission: (payload, resolve, reject) => makeRequest(`admin/reviewsByTempworkerByMission?token=${payload.token}&idUser=${payload.idUser}&idMission=${payload.idMission}`, "get", payload, resolve, reject),
    getTempWorkerInviteMission: (payload, resolve, reject) => makeRequest(`admin/getTempWorkerInviteMission?token=${payload.token}&idMission=${payload.idMission}`, "get", payload, resolve, reject),
    getNbReviewsTempWorker: (payload, resolve, reject) => makeRequest(`admin/nbReviewsTempWorker?token=${payload.token}&idUser=${payload.idUser}`, "get", payload, resolve, reject),
    getNationalities: (payload, resolve, reject) => makeRequest(`admin/nationalities?token=${payload.token}`, "get", {}, resolve, reject),
    getAllQualifications: (payload, resolve, reject) => makeRequest(`qualification/getAllQualifications?token=${payload.token}&search=${payload.search}&limit=${payload.limit}&page=${payload.page}&optionSearch=${payload.optionSearch}`, "get", payload, resolve, reject),
    postQualification: (payload, resolve, reject) => makeRequest("qualification/postQualification", "post", payload, resolve, reject),
    updateQualification: (payload, resolve, reject) => makeRequest("qualification/putQualification", "put", payload, resolve, reject),
    getQualificationByID: (payload, resolve, reject) => makeRequest(`qualification/getQualificationByID?token=${payload.token}&idQualification=${payload.idQualification}`, "get", payload, resolve, reject),
    delQualification: (payload, resolve, reject) => makeRequest("qualification/delQualification", "delete", payload, resolve, reject),
    activateQualification: (payload, resolve, reject) => makeRequest("qualification/activateQualification", "put", payload, resolve, reject),
    postExcludeAgency: (payload, resolve, reject) => makeRequest("vivier/postExcludeAgency", "post", payload, resolve, reject),
    getLocalisationPreferenceUser: (payload, resolve, reject) => makeRequest(`admin/localisationPreference?token=${payload.token}&idUser=${payload.idUser}`, "get", payload, resolve, reject),
    updLocalisationPreferenceUsers: (payload, resolve, reject) => makeRequest("admin/localisationPreferenceUsers", "put", payload, resolve, reject),
    deleteVivier: (payload, resolve, reject) => makeRequest("vivier/delete", "post", payload, resolve, reject),
    deleteVivierUser: (payload, resolve, reject) => makeRequest("vivier/deleteCandidateVivier", "post", payload, resolve, reject),
    updUserVivierPreferenceStatus: (payload, resolve, reject) => makeRequest("vivier/updUserVivierPreferenceStatus", "put", payload, resolve, reject),
    getCommentsByTempWorker: (payload, resolve, reject) => makeRequest(`admin/commentsByTempWorker?token=${payload.token}&idUser=${payload.idUser}`, "get", payload, resolve, reject),
    getNbCommentsByTempWorker: (payload, resolve, reject) => makeRequest(`admin/nbCommentsByTempWorker?token=${payload.token}&idUser=${payload.idUser}`, "get", payload, resolve, reject),
    postCommentTempWorker: (payload, resolve, reject) => makeRequest("admin/commentTempWorker", "post", payload, resolve, reject),
    deleteCommentTempWorker: (payload, resolve, reject) => makeRequest("admin/commentTempWorker", "delete", payload, resolve, reject),
    getVivierById: (payload, resolve, reject) => makeRequest(`vivier/vivierById?token=${payload.token}&idVivier=${payload.idVivier}`, "get", payload, resolve, reject),
    updateUsersVivierStatus: (payload, resolve, reject) => makeRequest("vivier/updateUsersVivierStatus", "put", payload, resolve, reject),
    getUserVivierArchiveReason: (payload, resolve, reject) => makeRequest(`admin/userVivierArchiveReason?token=${payload.token}&idUser=${payload.idUser}`, "get", payload, resolve, reject),
    getUserViviers: (payload, resolve, reject) => makeRequest(`admin/userViviers?token=${payload.token}&idUser=${payload.idUser}`, "get", payload, resolve, reject),
    getUserMissions: (payload, resolve, reject) => makeRequest(`admin/userMissions?token=${payload.token}&idUser=${payload.idUser}`, "get", payload, resolve, reject),
    getCollectiveAgreement: (payload, resolve, reject) => makeRequest(`admin/collectiveAgreement?token=${payload.token}`, "get", {}, resolve, reject),
    deleteAdminVivierFavorite: (payload, resolve, reject) => makeRequest("admin/adminVivierFavorite", "delete", payload, resolve, reject),
    searchCompanyInfosBySiret: (payload, resolve, reject) => makeRequest(`admin/searchCompanyInfosBySiret?token=${payload.token}&siret=${payload.siret}`, "get", payload, resolve, reject),
    postAdminVivierFavorite: (payload, resolve, reject) => makeRequest("admin/adminVivierFavorite", "post", payload, resolve, reject),
    searchCompanyInfosByName: (payload, resolve, reject) => makeRequest(`admin/searchCompanyInfosByName?token=${payload.token}&name=${payload.name}`, "get", payload, resolve, reject),
    getTimeSheetHoursVerified: (payload, resolve, reject) => makeRequest("admin/getTimeSheetHoursVerified", "post", payload, resolve, reject),
    getVivierListUsers: (payload, resolve, reject) => makeRequest(`vivier/vivierListUser?page=${payload.page}&limit=${payload.limit}&search=${payload.search}&optionSearch=${payload.optionSearch}&profil=${payload.profil}&filterOptions=${payload.filterOptions}`, "get", payload, resolve, reject),
    getVivierAllPage: (payload, resolve, reject) => makeRequest(`vivier/vivierAllPage?token=${payload.token}&page=${payload.currentPage}&limit=${payload.limitElement}&GP=${payload.selectGP}&MQ=${payload.selectMQ}&search=${payload.search}&sortName=${payload.sortName}`, "get", payload, resolve, reject),
    getVivierFiche: (payload, resolve, reject) => makeRequest(`vivier/vivierFiche?page=${payload.page}&limit=${payload.limit}&search=${payload.search}&optionSearch=${payload.optionSearch}&profil=${payload.profil}&id=${payload.id}&filterOptions=${payload.filterOptions}`, "get", payload, resolve, reject),
    getMissionVivier: (payload, resolve, reject) => makeRequest(`vivier/missionVivier?idVivier=${payload.idVivier}&vivierStatus=${payload.vivierStatus}&department=${payload.department}&page=${payload.page}&search=${payload.search}`, "get", payload, resolve, reject),
    getLogVivier: (payload, resolve, reject) => makeRequest(`vivier/logVivier?token=${payload.token}&idVivier=${payload.idVivier}`, "get", payload, resolve, reject),
    getVivierAll: (payload, resolve, reject) => makeRequest(`vivier/vivierAll`, "get", payload, resolve, reject),
    postCancelMission: (payload, resolve, reject) => makeRequest(`admin/cancelMission`, "post", payload, resolve, reject),
    postDeleteMission: (payload, resolve, reject) => makeRequest(`admin/deleteMission`, "post", payload, resolve, reject),
    getInfoMissionCancelDelete: (payload, resolve, reject) => makeRequest(`mission/infoMissionCancelDelete?token=${payload.token}&idMission=${payload.idMission}&isAdmin=${payload.isAdmin}`, "get", payload, resolve, reject),
    getDepartmentMissions: (payload, resolve, reject) => makeRequest(`admin/departmentMissions?token=${payload.token}`, "get", payload, resolve, reject),
    postTempWorkerAssignationRefused: (payload, resolve, reject) => makeRequest(`admin/tempWorkerAssignationRefused`, "post", payload, resolve, reject),
    postDeleteContractHistory: (payload, resolve, reject) => makeRequest(`admin/deleteContractHistory`, "post", payload, resolve, reject),
    getQualificationsActive: (payload, resolve, reject) => makeRequest(`qualification/getQualificationsActive?token=${payload.token}`, "get", payload, resolve, reject),
    postMissionDetail: (payload, resolve, reject) => makeRequest(`admin/postMissionDetail`, "post", payload, resolve, reject),
    postViviersMission: (payload, resolve, reject) => makeRequest(`admin/postViviersMission`, "post", payload, resolve, reject),
    postViviersMissionNotification: (payload, resolve, reject) => makeRequest(`admin/postViviersMissionNotification`, "post", payload, resolve, reject),
    postViviersMissionNotificationGlobal: (payload, resolve, reject) => makeRequest(`admin/postViviersMissionNotificationGlobal`, "post", payload, resolve, reject),
    getVivierNotificationMission: (payload, resolve, reject) => makeRequest(`admin/getVivierNotificationMission?token=${payload.token}&idMission=${payload.idMission}`, "get", payload, resolve, reject),
    getLastMissionNotificationGlobal: (payload, resolve, reject) => makeRequest(`admin/getLastMissionNotificationGlobal?token=${payload.token}&idMission=${payload.idMission}`, "get", payload, resolve, reject),
    connectSocketWithToken,
    cache,
    cacheableEndpoints,
    generateCacheKey,
    updateCacheEntry,
};

export default ApiDatabase;
