import React, {useEffect, useState} from "react";
import ApiDatabase from "server";
import Divider from "../../../../divider/divider";
import StarRating from "../../../../starRating/starRating";
import {ChevronDown, ChevronRight, NegativeThumb, PositiveThumb} from "../../../../../assets/icons";
import Tooltip from "../../../../tooltips/Tooltips";
import usefetchdata from "../../../../../hooks/usefetchdata";

const ReviewTempWorker = ({idUser}) => {
    const token = localStorage.getItem('xsrfToken')
    const {userData} = usefetchdata()
    const [reviewsByMission, setReviewsByMission] = useState([])
    const [isWaiting, setIsWaiting] = useState(true);
    const [openMissionReview, setOpenMissionReview] = useState({});

    useEffect(() => {
        if (token && idUser && userData._id) {
            setIsWaiting(true)
            setReviewsByMission([])
            ApiDatabase.getReviewsByTempWorkerGroupByMissions({token, idUser}, (data) => {
                setIsWaiting(false)
                setReviewsByMission(data.missionsReviews)
            }, (err) => {
                setIsWaiting(false)
            })
        }
    }, [userData._id, token, idUser]);

    return (
        <>
            {isWaiting ? (
                <div className={'animate-pulse flex flex-col gap-2'}>
                    {[1, 2, 3, 4, 5].map((i, index) => (
                        <div key={index}>
                            <div className={'flex justify-between gap-2'}>
                                <div className={'my-auto'}>
                                    <div className="h-2 w-60 bg-gray-200 rounded"></div>
                                </div>
                                <div className={'my-auto flex gap-4'}>
                                    <div className={'my-auto'}>
                                        <div className="h-2 w-32 bg-gray-200 rounded"></div>
                                    </div>
                                    <div className={'my-auto cursor-pointer border rounded w-9 h-9 flex'}>
                                        <div className="h-4 w-4 bg-gray-200 rounded m-auto"></div>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                        </div>
                    ))}
                </div>
            ) : (
                <>
                    {reviewsByMission?.map((mission, index) => (
                        <>
                            <div key={index} className={'flex justify-between gap-2'}>
                                <div className={'my-auto'}>
                                    {mission.title}
                                </div>
                                <div className={'my-auto flex gap-4'}>
                                    <div className={'my-auto'}>
                                        <Tooltip text={`${Math.round(mission.rating * 100) / 100}/5 avis positif`}
                                                 placement='top'>
                                            <div><StarRating rating={mission.rating}/></div>
                                        </Tooltip>
                                    </div>
                                    <div
                                        className={'my-auto cursor-pointer border rounded hover:bg-gray-100 w-9 h-9 flex'}
                                        onClick={() => {
                                            setOpenMissionReview(prevState => ({
                                                ...prevState,
                                                title: openMissionReview.title === mission.title ? '' : mission.title,
                                            }))
                                        }}>
                                        <div className={'m-auto'}>
                                            {openMissionReview.title === mission.title ?
                                                <ChevronDown w={20} h={8} color={'#374151'}/> :
                                                <ChevronRight wh={20} color={'#374151'}/>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {openMissionReview.title === mission.title && (
                                <div className={'flex flex-col gap-2'}>
                                    {mission.reviews.map((review, index) => (
                                        <div key={index} className={'border rounded-lg p-4'}>
                                            <div className={'flex flex-col gap-6'}>
                                                <div className={'flex justify-between gap-3'}>
                                                    <div className={''}>
                                                        <div className={''}>
                                                            {review?.type === 'positive' && 'Avis positif'}
                                                            {(review?.type === 'negative' && review?.type_negative === 'performance') && 'Performance'}
                                                            {(review?.type === 'negative' && review?.type_negative === 'professionalism') && 'Professionalisme'}
                                                        </div>
                                                        <div className={'text-gray-500'}>
                                                            {review.comment}
                                                        </div>
                                                    </div>
                                                    <div className={''}>
                                                        {review?.type === 'positive' && (
                                                            <div
                                                                className={'my-auto w-fit rounded-full p-2 bg-green-50'}>
                                                                <PositiveThumb wh={20} color={'#10B981'}/>
                                                            </div>
                                                        )}
                                                        {review?.type === 'negative' && (
                                                            <div className={'my-auto w-fit rounded-full p-2 bg-red-50'}>
                                                                <NegativeThumb wh={24} color={'#EF4444'}/>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className={'flex justify-between gap-3'}>
                                                    <div className={'my-auto text-gray-500'}>
                                                        Par {review?.employee?.firstname} {review?.employee?.lastname}
                                                    </div>
                                                    <div className={'my-auto'}>
                                                        {review?.mission?.title} n°{review?.mission?.id_mission}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                            <Divider key={index}/>
                        </>
                    ))}
                </>
            )}
        </>
    )
}

export default ReviewTempWorker;